import { IsoCountryCode2Char, PosVendor } from '@rbilabs/intl-common';

import {
  GetUserPermissionsByOktaIdDocument,
  OverrideId,
  RestaurantFragmentFragment,
  ServiceMode,
} from 'src/graphql';
import { Channel } from 'src/graphql/__generated__/main';
import { supportedLocalesByRegion } from 'src/locale/constants';
import { getServerUrl, vendorToProductPlu } from 'src/utils';

import type { EditorState } from './types';

const SELECTED_COUNTRY_STORE_KEY = 'selected-contry';

export type BatchPlus = {
  plu: string;
  sanityId: string;
  price?: number | null;
  availability?: boolean | null;
  draft?: string | null;
  overrides?: {
    id: OverrideId;
    price: number;
  }[];
  priority?: number;
  repetition?: string[];
};

export type Batch = {
  region: string;
  restaurantPos: PosVendor;
  channel: Channel;
  serviceMode: ServiceMode;
  storeIds: Array<RestaurantFragmentFragment['id']>;
  plus: Array<BatchPlus>;
};

interface IPrepareBatches extends EditorState {
  allowChangePrice: boolean | undefined;
  allowChangeAvailability: boolean | undefined;
}

/**
 * Creates batches per store pos vendor, service mode and channel
 */
export function prepareBatches({
  region,
  products,
  restaurants,
  initialUpdates,
  updates,
  allowChangePrice,
  allowChangeAvailability,
}: Omit<
  IPrepareBatches,
  'MAX_STORES_PER_BATCH' | 'MAX_PRODUCTS_PER_BATCH' | 'validatedBatches' | 'quickFill'
>): Array<Batch> {
  if (!restaurants) {
    return [];
  }

  // All stores in a batch should have the same POS Vendor
  return Object.entries(restaurants).reduce<Array<Batch>>((payload, [vendor, storeIds]) => {
    // Parse the updates and prepare the batches into groups
    const batchGroup = updates.reduce<Record<string, Batch>>((hash, update) => {
      const batchId = `${vendor}::${update.serviceMode}::${update.channel}`;
      const body = hash[batchId] ?? {};

      body.region = body.region ?? region;
      body.restaurantPos = body.restaurantPos ?? vendor;
      body.channel = body.channel ?? update.channel;
      body.serviceMode = body.serviceMode ?? update.serviceMode;
      body.storeIds = body.storeIds ?? storeIds;
      body.plus = body.plus ?? [];

      const productPlus = products.find(product => update.product === product.id)?.plus ?? {};
      const pluKey = vendorToProductPlu(vendor as PosVendor, update.serviceMode as ServiceMode);
      const plu = productPlus[pluKey]?.value;

      // if there's no plu means that the product is not configured
      // for the current POS Vendor / ServiceMode combination
      if (plu) {
        const originalUpdate = initialUpdates.find(initialUpdate => initialUpdate.id === update.id);

        const data: BatchPlus = {
          plu,
          sanityId: update.product,
          price: originalUpdate?.price,
          availability: originalUpdate?.availability,
          overrides: originalUpdate?.overrides as unknown as BatchPlus['overrides'],
        };

        if (allowChangePrice && typeof update.price === 'number') {
          data.price = update.price;
        }

        if (allowChangePrice && update.overrides && update.overrides.length) {
          data.overrides = update.overrides as unknown as BatchPlus['overrides'];
        }

        if (allowChangeAvailability) {
          data.availability = update.availability;
        }

        body.plus.push(data);
        hash[batchId] = body;
      }

      return hash;
    }, {});

    // Add each batch group to the final payload
    Object.values(batchGroup).forEach(batch => payload.push(batch));
    return payload;
  }, []);
}

/**
 * Split batches per max store size
 */
export function splitBatches(batches: Array<Batch>, MAX_STORES_PER_BATCH: number): Array<Batch> {
  // ensure valid amount of stores is passed
  let amount = MAX_STORES_PER_BATCH;

  if (amount < 1) {
    amount = 1;
    // eslint-disable-next-line no-console
    console.warn(
      `Invalid MAX_STORES_PER_BATCH ${MAX_STORES_PER_BATCH}. Value should be at least 1`
    );
  }

  return batches.reduce<Array<Batch>>((final, current) => {
    if (current.storeIds.length <= amount) {
      final.push(current);
      return final;
    }

    // calc n of slices
    const slices = Math.ceil(current.storeIds.length / amount);

    // split batch in difented and add it to the payload
    for (let i = 0; i < slices; i++) {
      // calc slice start and end indexes of the current slice
      const start = i === 0 ? i : amount * i;
      const end = i === 0 ? amount : amount * i + amount;

      // slice store ids and copy its plus
      const storeIds = [...current.storeIds].slice(start, end);
      const plus = [...current.plus];

      // add the sliced batch to the list of batches
      const batch = { ...current, storeIds, plus };
      final.push(batch);
    }

    return final;
  }, []);
}

/**
 * Queries the the GraphQL BE to fecth the user permissions
 */
export const fetchUserPermissions = async ({
  country,
  oktaId,
}: {
  country: IsoCountryCode2Char;
  oktaId: string;
}) => {
  const locale = supportedLocalesByRegion[country][0].value ?? 'en-US';
  const uri = `${getServerUrl(country)}/v1/graphql`;
  const getUserPermissionsQuery = GetUserPermissionsByOktaIdDocument.loc?.source.body;
  const token =
    JSON.parse(localStorage.getItem('okta-token-storage') ?? '{}')?.idToken?.idToken ?? '';

  if (!uri) {
    throw new Error('GraphQL endpoint is not defined');
  }

  const response = await fetch(uri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-ui-locale': locale,
      'x-ui-region': country,
      'okta-id-token': token,
    },
    body: JSON.stringify({
      query: getUserPermissionsQuery,
      variables: {
        oktaId,
      },
    }),
  });

  if (!response.ok) {
    throw new Error('GetUserPermissionsByOktaId petition failed');
  }

  return await response.json();
};

export const getLatestSelectedCountry = (): IsoCountryCode2Char | null => {
  return window.localStorage.getItem(SELECTED_COUNTRY_STORE_KEY) as IsoCountryCode2Char;
};

export const setSelectedCountry = (country: IsoCountryCode2Char): void => {
  window.localStorage.setItem(SELECTED_COUNTRY_STORE_KEY, country);
};
